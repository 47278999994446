import React, { useRef } from 'react'

// Libraries
import { Col, Container,Navbar, Row } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import { m, AnimatePresence } from 'framer-motion';
import { Formik, Form } from 'formik';

// Components
import {
  Header,
  HeaderCart,
  HeaderLanguage,
  HeaderNav,
  Menu,
  SearchBar,
} from "../../Components/Header/Header";
import { fadeIn } from '../../Functions/GlobalAnimations';
import { resetForm, sendEmail } from "../../Functions/Utilities";
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { ContactFormStyle02Schema, ContactFormStyle03Schema, } from '../../Components/Form/FormSchema';
import MessageBox from '../../Components/MessageBox/MessageBox';
import FooterStyle01 from "../../Components/Footers/FooterStyle01";

const FrontEndDev = (props) => {
  const form1 = useRef(null)
  const recaptcha = useRef()
  // {console.log(ContactFormStyle03Schema )}
  return (
  
 <div style={props.style}>
  <Header topSpace={{ md: true }} type="reverse-scroll">
        <HeaderNav
          theme="dark"
          expand="lg"
          className="py-[0px] lg:px-[15px] md:px-0"
          containerClass="sm:px-0"
        >
          <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/webp/logo-gradient-sky-blue-pink.webp"
                  data-rjs="/assets/img/webp/logo-white@2x.webp"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/webp/logo-gradient-sky-blue-pink.webp"
                  data-rjs="/assets/img/webp/logo-fast-blue-black@2x.webp"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/webp/logo-gradient-sky-blue-pink.webp"
                  data-rjs="/assets/img/webp/logo-fast-blue-black@2x.webp"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <div className="col-auto hidden order-last md:block">
            <Navbar.Toggle className="md:ml-[10px] sm:ml-0">
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse className="col-auto px-0 justify-end">
            <Menu {...props} />
          </Navbar.Collapse>
          <Col className="col-auto text-right pe-0">
            <SearchBar className="pr-0 xs:pl-[15px]" />
            <HeaderLanguage className="xs:pl-[15px]" />
            {/* <HeaderCart
              className="xs:pl-[15px]"
              style={{ "--base-color": "#0038e3" }}
            /> */}
          </Col>
        </HeaderNav>
      </Header>


      {/* Section Start */}
      <section
        style={{
          background: "linear-gradient(to right, #000000, #4b3621)", // Black to brown gradient
          color: "#fff", // White text
          padding: "100px 0", // Adjust padding for vertical alignment
          textAlign: "center", // Center text
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1
                style={{
                  fontFamily: "'Times New Roman', serif", // Serif font
                  fontSize: "3rem", // Large font size
                  fontWeight: "bold", // Bold text
                }}
              >
                Front End Developer
              </h1>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Section End */}  


  
      <m.section className="py-[100px] lg:py-[120px] md:py-[75px] sm:py-[50px] xs:py-[80px] xxs:py-[50px]" {...fadeIn}>
        <Container>

          <Row className="justify-center">
            <Col lg={8} md={10} sm={12}>
              <h6 className="font-serif text-gray-900 font-medium mb-[20px]">
                Job Description
              </h6>
              <p className="text-md text-gray-700 mb-[20px] ">
                We are looking for a Front-End Web Developer who is motivated to combine the art of design with the art of programming. Responsibilities will include translation of the UI/UX design wireframes to actual code that will produce visual elements of the application. You will work with the UI/UX designer and bridge the gap between graphical design and technical implementation, taking an active role on both sides and defining how the application looks as well as how it works.
              </p>
              <h6 className="font-serif text-gray-900 font-medium mb-[20px]">
                Responsibilities
              </h6>
              <ul className="list-disc pl-[20px] text-gray-700 mb-[20px]">
                <li>Develop new user-facing features</li>
                <li>Build reusable code and libraries for future use</li>
                <li>Ensure the technical feasibility of UI/UX designs</li>
                <li>Optimize application for maximum speed and scalability</li>
                <li>Assure that all user input is validated before submitting to back-end</li>
                <li>Collaborate with other team members and stakeholders</li>
              </ul>
              <h6 className="font-serif text-gray-900 font-medium mb-[20px] ">
                Skills
              </h6>
              <ul className="list-disc pl-[20px] text-gray-700 mb-[20px]">
                <li>Proficient understanding of web markup, including HTML5, CSS3</li>
                <li>Basic understanding of server-side CSS pre-processing platforms, such as LESS and SASS</li>
                <li>Proficient understanding of client-side scripting and JavaScript frameworks, including jQuery</li>
                <li>Good understanding of asynchronous request handling, partial page updates, and AJAX</li>
                <li>Proficient understanding of cross-browser compatibility issues and ways to work around them.</li>
                <li>Understanding of code versioning tools, such as Git / Mercurial / SVN / TFS</li>
                <li>Good understanding of SEO principles and ensuring that application will adhere to them.</li>
                <li>Experience with working on .Net Framework</li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col className='mb-[6%]'>
              <h4 className="font-serif text-gray-900 text-center font-medium mb-[10px]">Quick Form</h4>
            </Col>
          </Row>
          <Row className="justify-center">
            <Col>
              <Formik
                initialValues={{ name: '', email: '', phone: '', message: '', file: null }}
                validationSchema={ContactFormStyle03Schema}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true);
                  const response = await sendEmail(values);
                  response.status === "success" && resetForm(actions);
                }}
              >
                {({ isSubmitting, status, setFieldValue }) => (
                  <Form>
                    <Row className="row-cols-1 row-cols-md-2 gap-y-[20px]">
                      <Col>
                        <Input
                          showErrorMsg={false}
                          type="text"
                          name="name"
                          className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                          placeholder="Name*"
                        />
                      </Col>
                      <Col>
                        <Input
                          showErrorMsg={false}
                          type="email"
                          name="email"
                          className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                          placeholder="Email Id*"
                        />
                      </Col>
                      <Col>
                        <Input
                          showErrorMsg={false}
                          type="tel"
                          name="phone"
                          className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                          placeholder="Phone Number*"
                        />
                      </Col>
                      <Col>
                        <Input
                          showErrorMsg={false}
                          type="file"
                          name="file"
                          className="py-[10px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                          onChange={(event) => setFieldValue("file", event.currentTarget.files[0])}
                        />
                      </Col>
                      <Col className="col-span-2">
                        <TextArea
                          className="border-[1px] border-solid border-[#dfdfdf] w-full py-[15px] px-[20px] text-md resize-none"
                          name="message"
                          placeholder="Message"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-[20px]">
                      <Col className="text-center">
                        <Buttons
                          ariaLabel="form button"
                          type="submit"
                          className={`text-xs tracking-[1px] rounded-full py-[12px] px-[28px] uppercase${isSubmitting ? " loading" : ""}`}
                          themeColor={["#000", "#333"]}
                          size="md"
                          color="#fff"
                          title="Apply"
                        />
                      </Col>
                    </Row>
                    <AnimatePresence>
                      {status && (
                        <Row>
                          <Col xs={12}>
                            <m.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                            >
                              <MessageBox
                                className="mt-[20px] py-[10px]"
                                theme="message-box01"
                                variant="success"
                                message="Your application has been submitted successfully!"
                              />
                            </m.div>
                          </Col>
                        </Row>
                      )}
                    </AnimatePresence>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
</m.section>

{/* Section Start */}
  <FooterStyle01
  theme="dark"
  className="bg-[#262b35] text-slateblue overflow-visible"
  />
{/* Section End */}


</div>

  
  )
}

export default FrontEndDev;